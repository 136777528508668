@import url(../../../node_modules/normalize.css/normalize.css);
@import "~bootstrap/scss/bootstrap";


.herorow {
  background-color: rgb(51,51,51);
  padding: 50px;
  height:100vh;
  position: relative;
}

.infobox {
  width:auto;
  background-color: #1f1f1f;
  color: #ffcc00;
  font-family: Consolas,Lucida Console,monospace;
  font-size: 1.0em;
  line-height: 1.65;
  padding: 25px;
  height: 180px;
  position: absolute;
  bottom: 50px;
}

svg   {
  height: 1rem;
  fill: #ffcc00;
}